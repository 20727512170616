export const batchItemSituations = {
  TO_RECONDITION: {
    id: 1,
    description: "A recondicionar"
  },
  IN_RECONDITION: {
    id: 2,
    description: "Em recondicionamento"
  },
  RECONDITIONED: {
    id: 3,
    description: "Recondicionado"
  },
  TO_DEMANUFACTURE: {
    id: 4,
    description: "A desmanufaturar"
  },
  IN_DEMANUFACTURE: {
    id: 5,
    description: "Em desmanufatura"
  },
  DEMANUFACTURED: {
    id: 6,
    description: "Desmanufaturado"
  },
  TESTING: {
    id: 7,
    description: "Testagem"
  },
  RETURNED: {
    id: 8,
    description: "Devolvido"
  }
};
