<template>
  <data-not-found
    :title="title"
    :imgSrc="require('@/assets/demanufacturing.svg')"
  />
</template>

<script>
  import DataNotFound from "@/modules/core/views/components/DataNotFound.vue";

  export default {
    name: "DemanufacturingNotFound",

    props: {
      title: {
        type: Array,
        required: true
      }
    },

    components: { DataNotFound }
  };
</script>

<style lang="scss" scoped></style>
