<template>
  <div>
    <v-menu transition="slide-x-transition" bottom right>
      <template v-slot:activator="{ on, attrs }">
        <v-icon v-bind="attrs" v-on="on">
          mdi-dots-horizontal
        </v-icon>
      </template>
      <v-list>
        <v-list-item
          :to="{
            name: 'demanufacturing-detail',
            params: { id: demanufactureItemId }
          }"
          class="d-flex align-center item-dropdown-user"
        >
          <v-list-item-title>
            <v-icon class="item-dropdown-icon">mdi-clipboard-edit</v-icon
            ><span class="pl-2">Ver Registro</span>
          </v-list-item-title>
        </v-list-item>
      </v-list>
    </v-menu>
  </div>
</template>

<script>
  export default {
    name: "DemanufacturingMenuItem",

    props: {
      demanufactureItemId: {
        type: Number,
        required: true
      }
    }
  };
</script>

<style lang="scoped" scoped></style>
