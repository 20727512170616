<template>
  <v-container>
    <title-header :title="$route.meta.title" />
    <v-card class="mt-5 pt-7">
      <div class="table-page d-flex flex-column justify-space-between">
        <div class="inherit-height">
          <template v-if="showFilters">
            <div class="row px-5 d-flex justify-end">
              <v-col cols="12" md="3">
                <v-select
                  :items="situations"
                  item-text="description"
                  item-value="id"
                  outlined
                  @change="setFilter('batchItemSituationId', $event)"
                  color="secondary-color-1"
                  label="Situação"
                ></v-select>
              </v-col>
              <v-col cols="12" md="3" v-if="userIsAdmin">
                <v-select
                  :items="crcs"
                  item-text="description"
                  item-value="id"
                  outlined
                  @change="setFilter('crcId', $event)"
                  color="secondary-color-1"
                  label="Todos CRCs"
                ></v-select>
              </v-col>
              <v-col cols="12" md="3">
                <v-text-field
                  @input="setFilterDebounced('search', $event)"
                  solo
                  flat
                  full-width
                  color="secondary-color-1"
                  background-color="white-1"
                  placeholder="Buscar Lote"
                  prepend-inner-icon="mdi-magnify"
                ></v-text-field>
              </v-col>
            </div>
            <v-divider></v-divider>
          </template>
          <template v-if="items.length">
            <v-data-table
              :headers="headers"
              :items="items"
              :options.sync="dataTableOptions"
              :server-items-length="tableInfo.totalItems"
              hide-default-footer
            >
              <template v-slot:[`item.id`]="{ item }">
                <demanufacturing-menu-item :demanufactureItemId="item.id" />
              </template>
            </v-data-table>
          </template>
          <template v-else>
            <div class="inherit-height d-flex flex-column justify-center">
              <demanufacturing-not-found :title="dataNotFound" />
            </div>
          </template>
        </div>

        <div v-if="items.length">
          <pagination
            :currentPage="tableInfo.currentPage"
            :totalPages="tableInfo.totalPages"
            :itemsPerPage="tableInfo.itemsPerPage"
            @currentPageChanged="handleCurrentPageChange($event)"
            @itemsPerPageChanged="handleItemsPerPageChange($event)"
          />
        </div>
      </div>
    </v-card>
  </v-container>
</template>

<script>
  import TitleHeader from "@/modules/core/views/components/TitleHeader";
  import Pagination from "@/modules/core/views/components/Pagination";
  import TableHandler from "@/modules/core/mixins/tableHandler";
  import MasksMixin from "@/modules/core/mixins/masksMixin";
  import DemanufacturingNotFound from "@/modules/stock/demanufacturing/views/components/DemanufacturingNotFound";
  import DemanufacturingMenuItem from "@/modules/stock/demanufacturing/views/components/DemanufacturingMenuItem";

  import { demanufacturingSituations } from "@/modules/stock/demanufacturing/enums/demanufacturingSituations.enum";

  import { mapGetters, mapActions } from "vuex";

  export default {
    name: "ListDemanufacturing",

    components: {
      TitleHeader,
      Pagination,
      DemanufacturingNotFound,
      DemanufacturingMenuItem
    },

    mixins: [MasksMixin, TableHandler],

    data: () => ({
      crcs: [],

      items: [],

      headers: [
        {
          text: "Lote",
          align: "start",
          value: "batchNumber"
        },
        { text: "Data Registro", value: "createdAt" },
        { text: "Peso Desmanufaturado", value: "demanufacturedWeight" },
        { text: "Peso Destinado", value: "destinedWeight" },
        { text: "Peso Total da Triagem", value: "screeningTotalWeight" },
        { text: "Status", value: "situation" },
        { value: "id" }
      ]
    }),

    mounted() {
      this.fetchData();
    },

    computed: {
      ...mapGetters({
        userIsAdmin: "user/getUserIsAdmin"
      }),

      situations() {
        return Object.values(demanufacturingSituations);
      },

      showFilters() {
        if (this.items.length) {
          return true;
        }
        return !this.items.length && !this.filterPristine;
      },

      dataNotFound() {
        if (!this.filterPristine) {
          return ["Nenhum resultado encontrado"];
        }
        return [
          "Você ainda não possui nenhum",
          "material para desmanufaturar",
          "Aguarde!"
        ];
      }
    },

    methods: {
      ...mapActions(["toggleSnackbar"]),
      ...mapActions("stock/demanufacturing", ["getDemanufacturingBatchs"]),

      async fetchData() {
        try {
          const payload = this.getPayloadData();
          const { data, meta } = await this.getDemanufacturingBatchs(payload);

          this.setTableInfo(meta);
          this.items = this.formatData(data);
        } catch (error) {
          this.toggleSnackbar({
            text: error?.data?.message ?? "Ocorreu um erro. Tente novamente",
            type: "error"
          });
        }
      },

      formatData(data) {
        return data.map(item => {
          return {
            ...item,
            id: item.batchId,
            createdAt: this.parseDate(item.createdAt, "dd/MM/yyyy")
          };
        });
      },

      updateData() {
        this.fetchData();
      }
    }
  };
</script>

<style lang="scss" scoped></style>
