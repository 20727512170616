import { batchItemSituations } from "@/modules/stock/batch/enums/batchItemSituations.enum";

export const demanufacturingSituations = {
  TO_DEMANUFACTURE: {
    ...batchItemSituations.TO_DEMANUFACTURE
  },
  IN_DEMANUFACTURE: {
    ...batchItemSituations.IN_DEMANUFACTURE
  }
};
